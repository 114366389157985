import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { reducer, initialState } from './reducer';

import Header from '../../components/Header';

import PhoneNumberField from './fields/PhoneNumberField';

import { completeRegistration } from './functions/completeRegistration';
import requestRegistrationData from './functions/requestRegistrationData';

import { GET_START_PAGE, PHONE_UPDATE } from '../../settings/endpoint';

import './Registration.scss';
import { useLocation } from 'react-router';

const PhonePage = () => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    window.scrollTo(pageXOffset, 0);
  }, [state.current_count, state.close_test_text]);

  useEffect(() => {
    axios
      .get(GET_START_PAGE)
      .then(res => {
        dispatch({ type: 'GET_LIVING_CITY_LIST', payload: res.data.living_city_list });

        return null;
      })
      .catch(err => new Error(err)); //
  }, []);

  const handleNext = () => {
    if (completeRegistration(state)) {
      dispatch({ type: 'SET_PENDING', payload: true });

      axios
        .post(PHONE_UPDATE(location.pathname.split('/')[2]), {
          ...requestRegistrationData(state),
        })
        .then(res => {
          dispatch({
            type: 'REGISTRATION_CONFIRM',
            payload: {
              link: res.data.userform_uuid === '' ? '' : `${window.location.origin}/test/${res.data.userform_uuid}`,
              text: res.data.register_phrase,
            }
          });

          return null;
        })  
        .catch(err => {
          dispatch({ type: 'SET_PENDING', payload: false });

          return new Error(err);
        });
    } else {
      dispatch({ type: 'VALIDATION_ON' })
    }
  };

  return (
    <div className='test-wrapper'>
      <KeyboardEventHandler
        handleKeys={['Enter']}
        onKeyEvent={() => {
          // if (!btnNextDisabled(state.current_count) && !state.isPending) {
          if (!state.is_pending) {
            handleNext();
          }
        }}
      />
      <div className='test'>
        <Header title={'Обновление номера телефона'} current_count={0} count={0} />

        <div className='test__question-wrapper'>
          <div
            className='test__question'
            onKeyPress={e => {
              if (e.key === 'Enter') {
                // if (!btnNextDisabled(state.current_count) && !state.is_pending) {
                if (!state.is_pending) {
                  handleNext();
                }
              }
            }}
          >
            {state.current_count === 1 && state.close_test_text === '' && (
              <>
                <PhoneNumberField state={state} dispatch={dispatch} />
              </>
            )}
            {state.close_test_text !== '' && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left' }}>{state.close_test_text.split(/\n/g).map(i => (
                  <>
                    {i}
                    <br />
                  </>
                ))}</h1>
              </>
            )}

          </div>
        </div>
        {state.close_test_text === '' && (
        <div className='test__btn-block'>
          <>
            <div className='test__btn-block' style={{ justifyContent: 'flex-start', padding: 0 }}>
              <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                <button onClick={() => handleNext()} className='test__link-button' disabled={state.is_pending} style={{ opacity: `${state.is_pending ? 0.7 : 1}` }}>Обновить</button>
              </div>
            </div>
          </>
        </div>
        )}
      </div>
    </div>
  );
};

export default PhonePage;
