import { citizenshipTextValidation, citizenshipValidation, emailAlfaValidation, dobValidation, emailValidation, firstNameValidation, lastNameValidation, livingCityValidation, middleNameValidation, phoneValidation } from "./validations";

export const registrationValidation = ({
  lastName,
  firstName,
  middleName,
  email,
  dob,
  phone,
  livingCity,
  pdAgreement,
  correct,
  citizenship,
  moscowRegion,
  moscowRegionChoices,
  citizenshipText,
  forAlfa = false,
}) => {
  // const isMoskow = moscowRegion === moscowRegionChoices[0]

  return (
    lastNameValidation(lastName) &&
    firstNameValidation(firstName) &&
    // middleNameValidation(middleName) &&
    (forAlfa ? emailAlfaValidation(email) : emailValidation(email)) &&
    // dobValidation(dob) &&
    // (isMoskow || (moscowRegion !== moscowRegionChoices[0] && livingCityValidation(livingCity))) &&
    phoneValidation(phone) &&
    // citizenshipValidation(citizenship) &&
    // (citizenship === '2. Другое' ? citizenshipTextValidation(citizenshipText) : true) &&
    // moscowRegion !== '' &&
    pdAgreement &&
    correct
  );
};
