import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './TableWithLevels.scss';

const TableWithLevels = ({ items, headers, marks, value, dispatch, dispatchType }) => {
  useEffect(() => {
    if (value === '' || value.length === 0) {
      dispatch({
        type: dispatchType,
        payload: items.map(() => undefined),
      });
    }
    // eslint-disable-next-line
  }, [dispatch, dispatchType]);

  const handleChecked = (index, val) => {
    const newAnswer = value.map((i, j) => (j === index ? val : i));

    dispatch({
      type: dispatchType,
      payload: newAnswer,
    });
  };

  return (
    <div className='table-question-wrapper'>
      <table className='table-question' border={1}>
        <thead className='table__headaer'>
          <td />
          {headers.map(i => {
            return <th>{i}</th>;
          })}
        </thead>
        <tbody>
          {items.map((answer, index) => (
            <>
              <tr className='radio-group-wrapper radio-group-wrapper-level' align='center'>
                <td>{answer}</td>
                {marks.map(i => {
                  return (
                    <td>
                      <label key={`radio-group-${index}-${i}`} htmlFor={`radio-group-${index}-${i}`} className='radio-group__label'>
                        <input
                          type='radio'
                          id={`radio-group-${index}-${i}`}
                          name='radio question'
                          value={`${answer}: ${i}`}
                          checked={value[index] === `${answer}: ${i}`}
                          onChange={e => handleChecked(index, e.target.value)}
                          className={`radio-group__radio ${value[index] === `${answer}: ${i}` ? 'checked' : ''}`}
                        />
                        <div className='radio-group__radio-indicator' />
                      </label>
                    </td>
                  );
                })}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableWithLevels.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  marks: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatchType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default TableWithLevels;
