import React from 'react';

const CorrectData = ({ state, dispatch }) => {
  return (
    <>
      <div className='pd-agreement' style={{ marginBottom: '10px' }}>
        <input
          type='checkbox'
          value='yes'
          className='pd-agreement__input'
          onChange={() => dispatch({ type: 'SET_CORRECT', payload: !state.correct })}
        />
        <input
          type='checkbox'
          value='yes'
          className='pd-agreement__input'
          onChange={() => dispatch({ type: 'SET_CORRECT', payload: !state.correct })}
        />
        <div className={`pd-agreement__checkbox ${state.correct ? 'checked' : ''}`} />
        <label className='pd-agreement__label' onClick={() => dispatch({ type: 'SET_CORRECT', payload: !state.correct })}>
            {/* eslint-disable-next-line */}
            Подтверждаю корректность предоставленных мною персональных данных
        </label>
      </div>
      {state.validation && !state.correct && (
        <span style={{ color: '#ef3124', fontSize: '12px', paddingTop: '10px' }}>Для регистрации необходимо подтвердить педоставленные персональные данные</span>
      )}
    </>
  );
};

export default CorrectData;
