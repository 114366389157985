import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Footer from '../../components/Footer';

import { PUT_CONFIRM } from '../../settings/endpoint';

import './ConfirmPage.scss';

const ConfirmPage = () => {
  const history = useHistory();
  const [exitPhrase, setExitPhrase] = useState('');
  const [surveyTitle, setSurveyTitle] = useState('');

  useEffect(() => {
    axios.put(PUT_CONFIRM(history.location.pathname.slice(14)))
    .then(res => {
      setExitPhrase(res.data.exit_phrase);
      setSurveyTitle(res.data.survey_title);
    })
    .catch(err => new Error(err));
  }, [history.location.pathname]);

  return (
    <div className='test-wrapper confirm-page'>
      <div className='test'>
        <div className='test__header'>
          <div className='test__header-title'>
            <div className='test__header-title-start' />
            <div className='test__header-title-finish' />
          </div>
          <div className='test__progress-wrapper'>
            <div style={{ width: '100%' }} className='test__progress' />
          </div>
        </div>
        <div className='test__header-direction'>
          <div className='test__header-title'>{surveyTitle}</div>
        </div>
        <div className='test__question-wrapper'>
          <h1 className='test__question-title' style={{ textAlign: 'center', paddingRight: '0' }}>
            {exitPhrase.split(/\n/g).map(i => (
              <>
                {i}
                <br />
              </>
            ))}
          </h1>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ConfirmPage;
