import axios from 'axios';
import ym from 'react-yandex-metrika';

import { POST_ANSWER } from '../../../settings/endpoint';

export const opened = ({ history, answer, dispatch }) => {
  axios
    .post(POST_ANSWER(history.location.pathname.slice(6)), answer)
    // eslint-disable-next-line
    .then(res => {
      dispatch({ type: 'setSelectAnswer', payload: '' });
      dispatch({ type: 'getLink', payload: res.data.link });
      dispatch({
        type: 'getQuestionCount',
        payload: {
          question_number: res.data.question_scale.question_number,
          questions_total: res.data.question_scale.questions_total,
        },
      });

      if (res.data.question !== undefined) {
        dispatch({ type: 'getQuestion', payload: { ...res.data.question, direction_title: res.data.survey_title } });
        dispatch({ type: 'setAnswerId', payload: res.data.answer_id });
      } else if (res.data.exit_phrase !== undefined) {
        if (process.env.NODE_ENV === 'production') {
          ym('reachGoal', 'form2');
        }
        dispatch({ type: 'getQuestion', payload: { exit_phrase: res.data.exit_phrase, question_types: '', telegram_link: res.data.telegram_link } });
      }

      dispatch({ type: 'setPending', payload: false });

      return null;
    })
    // eslint-disable-next-line
    .catch(err => {
      dispatch({ type: 'setError' });
      dispatch({ type: 'setPending', payload: false });

      return new Error(err);
    });
};