/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import ProgressBar from '../ProgressBar/ProgressBar';

import './Header.scss';

const Header = ({ title, current_count, count }) => {
  return (
    <>
      <header className='header'>
        <div className='header_container'>
          {/* <div className='header__direction'>
            <div className='header__direction-title'>{title}</div>
            <div className='header__direction-count'>{`${current_count}/${count}`}</div>
          </div> */}
        </div>
      </header>
      <ProgressBar current_count={current_count} count={count} />
      {/* <div className='test__header'>
        <div className='test__header-title'>
          <div className='test__header-title-start' />
          <div className='test__header-title-finish' />
        </div>
        <div className='test__progress-wrapper'>
          <div style={{ width: `${(100 / count) * current_count}%` }} className='test__progress' />
        </div>
      </div>
      <div className='test__header-direction'>
        <div className='test__header-title'>{title}</div>
        <div className='test__header-count'>{`${current_count}/${count}`}</div>
      </div> */}
    </>
  );
};

export default Header;
