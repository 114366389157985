import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <h3>Команда Alfa Battle Meta</h3>
      {/* <div className='t-sociallinks__wrapper'>
        <div className='t-sociallinks__item'>
          <a href='https://msngr.link/tg/alfafactory' target='_blank' rel='noopener noreferrer'>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" className=""><g><circle cx="12" cy="12" fill="#039be5" r="12" data-original="#039BE5" className="" style={{ fill : "#55B435"}}/><path d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z" fill="#fff" className="active-path" style={{ fill: "#FFFFFF"}}/></g> </svg>
            @alfafactory
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
