import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import './CheckboxGroupWithOther.scss';

const CheckboxGroupWithOther = ({ items, value, dispatch, dispatchType, otherQ, otherDispatchType, otherAnswer, countAnswer }) => {
  useEffect(() => {
    if (value === '') dispatch({ type: dispatchType, payload: [] });
  }, [dispatch, dispatchType, items.length, value]);

  const handleChecked = val => {
    const newAnswer = value;

    if (value.includes(val)) {
      dispatch({ type: dispatchType, payload: newAnswer.filter(i => i !== val) });
    } else {
      newAnswer.push(val);

      dispatch({ type: dispatchType, payload: newAnswer.filter(i => !otherAnswer.includes(i)) });
    }
  };

  const disableItem = useCallback((val) => {
    const isLimit = countAnswer !== undefined && countAnswer !== 0
    const isOutOfLimit = value.length === countAnswer
    const isNotActive = !value.includes(val)

    return isLimit && isOutOfLimit && isNotActive
  }, [countAnswer, value])

  return (
    <div className='checkbox-group'>
      {items.map((answer, index) => (
        <div key={`radion-group-${answer.id}`} className={`pd-agreement ${disableItem(answer) ? 'itemDisabled' : ''}`}>
          <label className='pd-agreement__label'>
            <input
              type='checkbox'
              name='checkbox answer'
              value={answer}
              checked={value.includes(answer)}
              className='pd-agreement__input'
              onChange={e => handleChecked(e.target.value)}
              disabled={disableItem(answer)}
            />
            <div className={`pd-agreement__checkbox ${value.includes(answer) ? 'checked' : ''}`} />
            {answer.split(/\n/g).map(i => (
              <>
                {i.replace(/\s/g, '\u00A0')}
                <br />
              </>
            ))}
          </label>
          {answer.includes(otherQ) && value.includes(items[index]) && (
            <div className='text-input'>
              <input
                type='text'
                value={otherAnswer}
                onChange={e => dispatch({ type: otherDispatchType, payload: e.target.value })}
                maxLength='50'
                placeholder='Впишите своё'
                disabled={disableItem(answer)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

CheckboxGroupWithOther.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
  otherQ: PropTypes
};

export default CheckboxGroupWithOther;
