import moment from "moment"
import { isDobOlder, isDobRange, olderDays, olderYYYY, yongerYYYY } from "../constants/settingsProject"

export const isEmptyString = str => str.trim().length === 0
export const testName = str => /^[А-ЯЁа-яёA-Za-z]+$/.test(str.trim())
export const testEmail = email => /^([a-zA-Z0-9-._+])+@([a-zA-Z0-9-_.])+\.([a-zA-Z]{2,30})$/i.test(email.trim())
export const testEmailAlfa = email => /^([a-zA-Z0-9-._+])+@alfabank.ru$/i.test(email.trim())
export const testDob = dob => /^(0[1-9]|1\d|2\d|3[01]).(0[1-9]|1[0-2]).(19|20)\d{2}$/.test(dob)
export const testLivingCity = city => /^[А-ЯЁа-яЁA-Za-z\s-]+$/.test(city.trim())
export const testCitizenship = city => /^[А-ЯЁа-яЁ\s-]+$/.test(city.trim())

export const firstNameValidation = name => {
  return !isEmptyString(name) && testName(name)
}

export const middleNameValidation = name => {
  return isEmptyString(name) || (isEmptyString(name) || testName(name))
}

export const lastNameValidation = name => {
  return !isEmptyString(name) || testName(name)
}

export const emailValidation = email => {
  return testEmail(email)
}

export const emailAlfaValidation = email => {
  return testEmailAlfa(email)
}

export const dobValidation = dob => {
  let isValidation = false

  if (isDobRange) {
    let isYounger = dob.split('.').reverse()[0] > yongerYYYY
    let isOlder = dob.split('.').reverse()[0] < olderYYYY

    isValidation = !isYounger && !isOlder
  }

  if (isDobOlder) {
    isValidation = moment().diff(dob.split('.').reverse().join('-'), 'day', false) >= olderDays
  }

  return testDob(dob) && isValidation
}

export const livingCityValidation = city => {
  return testLivingCity(city)
}

export const phoneValidation = phone => {
  return phone.trim().length === 11 && phone.trim()[0] == 7 && phone.trim()[1] == 9
}

export const citizenshipValidation = citizenship => {
  return citizenship && citizenship !== ''
}

export const citizenshipTextValidation = text => {
  return !isEmptyString(text) && testCitizenship(text)
}
