import axios from 'axios';
import ym from 'react-yandex-metrika';

import { POST_ANSWER } from '../../../settings/endpoint';

export const file = ({ history, state, dispatch }) => {
  const formData = new FormData();
  const data = {
    user_answer: ['---'],
    question: state.question.id,
  }

  if (state.selectAnswer.file) {
    formData.append('file_attachment', state.selectAnswer.file);
    formData.append('user_answer', state.selectAnswer.file?.name);
    formData.append('question', state.question.id);
  }

  console.log(state.selectAnswer)

  axios
    .post(POST_ANSWER(
      history.location.pathname.slice(6)),
      state.selectAnswer.file ? formData : state.selectAnswer.url ? { user_answer: [state.selectAnswer.url], question: state.question.id } : data,
      state.selectAnswer.file ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {},
    )
    .then(res => {
      dispatch({ type: 'setSelectAnswer', payload: '' });
      dispatch({ type: 'getLink', payload: res.data.link });
      dispatch({
        type: 'getQuestionCount',
        payload: {
          question_number: res.data.question_scale.question_number,
          questions_total: res.data.question_scale.questions_total,
        },
      });

      if (res.data.question !== undefined) {
        dispatch({ type: 'getQuestion', payload: { ...res.data.question, direction_title: res.data.survey_title } });
        dispatch({ type: 'setAnswerId', payload: res.data.answer_id });
      } else if (res.data.exit_phrase !== undefined) {
        if (process.env.NODE_ENV === 'production') {
          ym('reachGoal', 'form2');
        }
        dispatch({ type: 'getQuestion', payload: { exit_phrase: res.data.exit_phrase, question_types: '', telegram_link: res.data.telegram_link } });
      }

      dispatch({ type: 'setPending', payload: false });

      return null;
    })
    .catch(err => {
      dispatch({ type: 'setError' });
      dispatch({ type: 'setPending', payload: false });

      return new Error(err);
    });
};