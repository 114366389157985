/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable no-undef */
import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ym from 'react-yandex-metrika';

import { reducer, initialState } from './reducer';

import RadioGroup from '../../components/RadioGroup';
import RadioGroupWithOther from '../../components/RadioGroupWithOther';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import CheckboxGroup from '../../components/CheckboxGroup';
import TableWithLevels from '../../components/TableWithLevels';
import Scale from '../../components/Scale';
import AutocompleteInput from '../../components/AutocompleteInput';
import CheckboxGroupWithOther from '../../components/CheckboxGroupWithOther';
import Footer from '../../components/Footer';

import { taskPreview } from './submit/taskPreview';
import { taskPreviewChoise } from './submit/taskPreviewChoise';
import { opened } from './submit/opened';
import { base } from './submit/base';
import { file } from './submit/file';

import { GET_QUESTION } from '../../settings/endpoint';

import './TestQuestions.scss';
import FileUploader from '../../components/FileUploader/FileUploader';
import Header from '../../components/Header';

// eslint-disable-next-line
const TestQuestions = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production' && state.question_number === 1) {
  //     ym('reachGoal', 'form2');
  //   }
  // }, [state.question_number]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    window.scrollTo(pageXOffset, 0);
  }, [state.question_number]);

  useEffect(() => {
    axios
      .get(GET_QUESTION(history.location.pathname.slice(6)))
      // eslint-disable-next-line
      .then(res => {
        dispatch({
          type: 'getQuestionCount',
          payload: {
            question_number: res.data.question_scale.question_number,
            questions_total: res.data.question_scale.questions_total,
          },
        });
        // eslint-disable-next-line
        if (res.data.question !== undefined) {
          dispatch({ type: 'setAnswerId', payload: res.data.answer_id });
          dispatch({ type: 'getLink', payload: res.data.link });
          dispatch({ type: 'getQuestion', payload: { ...res.data.question, direction_title: res.data.survey_title } });

          return null;
        } else if (res.data.exit_phrase !== undefined) {
          if (process.env.NODE_ENV === 'production') {
            ym('reachGoal', 'form2');
          }

          return dispatch({ type: 'getQuestion', payload: { exit_phrase: res.data.exit_phrase, question_types: '', telegram_link: res.data.telegram_link } });
        }
      })
      .catch(err => new Error(err));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = arg => {
    dispatch({ type: 'setPending', payload: true });

    // if (process.env.NODE_ENV === 'production') {
    //   ym('reachGoal', `step${state.question_number}`);
    // }

    const answer = {
      question: state.question.id,
      user_answer: typeof state.selectAnswer === 'object' ? state.selectAnswer : [state.selectAnswer],
    };

    if (state.question.question_types === 'multi_single') {
      answer.user_answer = state.selectAnswer.filter(i => i !== undefined);
    }

    if (state.question.question_types === 'coding_multi') {
      answer.user_answer = state.selectAnswer.map(i => i.replace(/\n/g, '|||'));
    }

    if (state.question.question_types === 'opened_with_load_list' && answer.user_answer[0] === '') {
      answer.user_answer = ['-'];
    }

    if (state.question.question_types === 'opened_and_empty' && answer.user_answer[0] === '') {
      answer.user_answer = ['-'];
    }

    if (state.question.question_types === 'single_other') {
      if (state.otherAnswer === '') {
        answer.user_answer = [`${state.selectAnswer}`];
      } else {
        answer.user_answer = [`${state.selectAnswer} - ${state.otherAnswer}`];
      }
    }

    if (state.question.question_types === 'multi_other') {
      answer.user_answer = state.selectAnswer.map(i => i !== state.question.question_other_input ? i : `5. ${state.otherAnswer}` );
    }

    if ((state.question.question_types === 'opened_text' || state.question.question_types === 'opened_text_empty') && state.selectAnswer.replace(/\n/g, ' ').trim().length !== 0) {
      answer.user_answer = [`${state.selectAnswer.replace(/\n/g, ' ').trim()}`];
    }

    if (state.question.question_types === 'opened_text_empty' && state.selectAnswer.replace(/\n/g, ' ').trim().length === 0) {
      answer.user_answer = ['-'];
    }

    if (state.question.question_types === 'finalize' && !state.question.is_test_passed) {
      base({ history, answer: { question: state.question.id, user_answer: ['-'] }, dispatch })
      return;
    }

    if (state.question.question_types === 'file_upload_or_url') {
      file({ history, state, dispatch })
      return;
    }

    if (state.question.question_types === 'task_preview_choice') {
      taskPreviewChoise({ history, state, dispatch, user_answer: arg })
      return;
    }

    if (state.question.question_types === 'task_preview') {
      taskPreview({ history, state, dispatch });
      return;
    }

    if (state.question.question_types === 'opened_and_empty' || state.question.question_types === 'opened_text_empty' || state.question.question_types === 'opened_with_load_list') {
      opened({ history, answer, dispatch })
      return;
    }

    if (state.selectAnswer.length !== 0 && state.question.question_types !== 'opened_and_empty') {
      base({ history, answer, dispatch })
      return;
    }
  };

  // eslint-disable-next-line
  const disableBtn = () => {
    if (state.question.question_types !== undefined && state.question.question_types === 'task_preview') {
      return false;
    }

    if (state.question.question_types !== undefined && state.question.question_types === 'opened_and_empty') {
      return false;
    }

    if (state.question.question_types === 'opened_text_empty' || state.question.question_types === 'opened_with_load_list') {
      return false;
    }

    if (state.question.question_types !== undefined && state.question.question_types === 'file_upload_or_url' ) {
      return false;
    }

    if (state.selectAnswer.length !== 0) {
      if (state.question.question_types === 'scale' || state.question.question_types === 'multi_line') {
        return state.selectAnswer.length !== state.selectAnswer.filter(i => i !== undefined).length || state.isPending;
      }

      if (state.question.question_types === 'opened_numbers') {
        return typeof +state.selectAnswer !== 'number'
      }

      if (state.question.question_types === 'single_other') {
        if (state.question.question_other_input === state.selectAnswer) {
          return state.otherAnswer.length === 0;
        }
      }

      if (state.question.question_types === 'opened_text') {
        return state.selectAnswer.replace(/\n/g, ' ').trim().length === 0;
      }

      if (state.question?.multiselect_required_count_answer && state.question?.multiselect_required_count_answer !== 0 && state.question.question_types === 'multi_single') {
        return state.selectAnswer.length !== state.question?.multiselect_required_count_answer
      }

      return state.selectAnswer.length === 0 || state.isPending;
    }

    return true;
  };

  return (
    <div className='test-wrapper'>
      {state.question.question_types !== undefined && state.question.question_types !== 'opened_text' && state.question.question_types !== 'opened_text_empty' && (
        <>
          <KeyboardEventHandler
            handleKeys={['Enter']}
            onKeyEvent={() => {
              if (
                state.question.question_types !== undefined &&
                state.question.question_types !== '' &&
                state.question.question_types !== 'finalize' &&
                !disableBtn()
              ) {
                handleSubmit();
              } else if (state.question.question_types !== undefined && state.question.question_types === 'finalize' && state.link !== '' && state.question.is_test_passed) {
                window.open(`${state.link}`);
              } else if (state.question.question_types !== undefined && state.question.question_types === 'finalize' && !state.question.is_test_passed) {
                handleSubmit();
              }
            }}
          />
        </>
      )}
      <div className='test'>
        <Header title={state.question.direction_title} current_count={state.question_number} count={state.questions_total} />

        <div className='test__question-wrapper'>
          <div
            className='test__question'
            onKeyPress={e => {
              if (e.key === 'Enter') {
                if (
                  state.question.question_types !== undefined &&
                  state.question.question_types !== '' &&
                  state.question.question_types !== 'finalize' &&
                  state.question.question_types !== 'opened_text' &&
                  state.question.question_types !== 'opened_text_empty' &&
                  !disableBtn()
                ) {
                  handleSubmit();
                } else if (state.question.question_types !== undefined && state.question.question_types === 'finalize' && state.link !== '' && state.question.is_test_passed) {
                  window.open(`${state.link}`);
                } else if (state.question.question_types !== undefined && state.question.question_types === 'finalize' && !state.question.is_test_passed) {
                  handleSubmit();
                }
              }
            }}
          >
            {state.question.question_types === '' && state.question.exit_phrase !== '' && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left', paddingRight: '0' }}>
                  {(() => {
                    if (state.question.exit_phrase.indexOf('https://t.me/alfa_battle_meta') !== -1) {
                      const arr = state.question.exit_phrase.split('https://t.me/alfa_battle_meta')

                      return (
                        <>
                          {arr[0].split(/\n/g).map((i, j) => (
                            <>
                              <span>{i}</span>
                              {arr[0].split(/\n/g).length - 1 !== j && (
                                <br />
                              )}
                            </>
                          ))} <span><a href='https://t.me/alfa_battle_meta' target='_blank' rel='noopener noreferrer'>@AlfaBattleMeta</a></span>
                          {arr[1].split(/\n/g).map(i => (
                            <>
                              <span>{i}</span>
                              <br />
                            </>
                          ))} 
                        </>
                      )
                    } else {
                      return state.question.exit_phrase.split(/\n/g).map(i => (
                        <>
                          {i}
                          <br />
                        </>
                      ))
                    }
                  })()}
                </h1>
                {/*/\(https:\/\/join.slack.com\/t\/alfafactory\/shared_invite\/zt-ep59od8z-hEBmwYNQbaHGBlwQ4igjYg\)/.test(state.question.exit_phrase) && (
                  <div className='test__question-title__link'>
                    <a href='https://join.slack.com/t/alfafactory/shared_invite/zt-ep59od8z-hEBmwYNQbaHGBlwQ4igjYg'>
                      <svg xmlns="http://www.w3.org/2000/svg" id="Bold" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"><g><path d="m8.843 12.651c-1.392 0-2.521 1.129-2.521 2.521v6.306c0 1.392 1.129 2.521 2.521 2.521s2.521-1.129 2.521-2.521v-6.306c-.001-1.392-1.13-2.521-2.521-2.521z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m.019 15.172c0 1.393 1.13 2.523 2.523 2.523s2.523-1.13 2.523-2.523v-2.523h-2.521c-.001 0-.001 0-.002 0-1.393 0-2.523 1.13-2.523 2.523z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m8.846-.001c-.001 0-.002 0-.003 0-1.393 0-2.523 1.13-2.523 2.523s1.13 2.523 2.523 2.523h2.521c0-1.431 0-1.083 0-2.528-.001-1.391-1.128-2.518-2.518-2.518z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m2.525 11.37h6.318c1.393 0 2.523-1.13 2.523-2.523s-1.13-2.523-2.523-2.523h-6.318c-1.393 0-2.523 1.13-2.523 2.523s1.13 2.523 2.523 2.523z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m21.457 6.323c-1.391 0-2.518 1.127-2.518 2.518v.005 2.523h2.521c1.393 0 2.523-1.13 2.523-2.523s-1.13-2.523-2.523-2.523c-.001 0-.002 0-.003 0z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m12.641 2.522v6.325c0 1.392 1.129 2.521 2.521 2.521s2.521-1.129 2.521-2.521v-6.325c0-1.392-1.129-2.521-2.521-2.521-1.392 0-2.521 1.129-2.521 2.521z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m17.682 21.476c0-1.392-1.129-2.521-2.521-2.521h-2.521v2.523c.001 1.391 1.129 2.519 2.521 2.519s2.521-1.129 2.521-2.521z" dataOriginal="#000000" class="active-path" fill="#EF3124"/><path d="m21.479 12.649h-6.318c-1.393 0-2.523 1.13-2.523 2.523s1.13 2.523 2.523 2.523h6.318c1.393 0 2.523-1.13 2.523-2.523s-1.13-2.523-2.523-2.523z" dataOriginal="#000000" class="active-path" fill="#EF3124"/></g> </svg>
                    </a>
                    <a href='https://join.slack.com/t/alfafactory/shared_invite/zt-ep59od8z-hEBmwYNQbaHGBlwQ4igjYg'>alfafactory.slack.com</a>
                  </div>
                )} */}
                {/* {state.question.telegram_link && (
                  <a href='https://t.me/joinchat/EIjdMRShg4W_WPzMfkYC1Q' className='test__question-title__link'>
                    @alfabattle2.0
                  </a>
                )} */}
                <Footer />
              </>
            )}
            {state.question.question_title !== undefined && state.question.question_types !== '' && state.question.question_types !== 'coding' && state.question.question_types !== 'coding_multi' && state.question.question_types !== 'task_preview' && state.question.question_types !== 'task_preview_choice' && state.question.question_types !== 'finalize' && (
              <>
                {/* Тут */}
                <h1 className='test__question-title'>{state.question.question_title.split(/\n/g).map(i => (
                    <>
                      {i}
                      <br />
                    </>
                  ))}</h1>
                <h3 className='test__question-subtitle'>{state.question.question_help_text}</h3>
              </>
            )}
            {(state.question.question_types === 'coding' || state.question.question_types === 'coding_multi') && (
              <>
                <h1 className='test__question-title'>{state.question.coding_title_1}</h1>
                <div style={{ margin: '30px 0 50px', overflow: 'auto'}}>
                  <code>
                    {state.question.coding_code_field_1.split(/\n/g).map(i => (
                      <>
                        {i.replace(/\s\s/g, '\u00A0')}
                        <br />
                      </>
                    ))}
                  </code>
                </div>
                <h1 className='test__question-titl  e'>{state.question.coding_title_2}</h1>
                <div style={state.question.coding_code_field_2 !== '' ? { margin: '30px 0 50px', overflow: 'auto'} : { height: '0' }}>
                  <code>
                    {state.question.coding_code_field_2.split(/\n/g).map(i => (
                      <>
                        {i.replace(/\s/g, '\u00A0')}
                        <br />
                      </>
                    ))}
                  </code>
                </div>
                <h1 className='test__question-title'>{state.question.coding_title_3}</h1>
                <div style={state.question.coding_code_field_3 !== '' ? { margin: '30px 0 50px', overflow: 'auto'} : { height: '0' }}>
                  <code>
                    {state.question.coding_code_field_3.split(/\n/g).map(i => (
                      <>
                        {i.replace(/\s/g, '\u00A0')}
                        <br />
                      </>
                    ))}
                  </code>
                </div>
              </>
            )}
            {state.question.question_types === 'task_preview' && (
              <>
              <h1 className='test__question-title' style={{ textAlign: 'left', paddingRight: '0' }}>

                {(() => {
                      if (state.question.question_title.indexOf('https://alfabanklive.ru/alfafactory/#rec221954324') !== -1) {
                        const arr = state.question.question_title.split('https://alfabanklive.ru/alfafactory/#rec221954324')

                        return (
                          <>
                            {arr[0].split(/\n/g).map((i, j) => (
                              <>
                                {i}
                                {j !== arr[0].split(/\n/g).length - 1 && (
                                  <br />
                                )}
                              </>
                            ))} <span><a href='https://alfabanklive.ru/alfafactory/#rec221954324' target='_blank' rel='noopener noreferrer'>https://alfabanklive.ru/alfafactory/#rec221954324</a></span>
                            {arr[1].split(/\n/g).map(i => (
                              <>
                                {i}
                                <br />
                              </>
                            ))} 
                          </>
                        )
                      } else {
                        return state.question.question_title.split(/\n/g).map(i => (
                          <>
                            {i}
                            <br />
                          </>
                        ))
                      }
                    })()}
                  </h1>
              </>
            )}
            {state.question.question_types === 'task_preview_choice' && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left', paddingRight: '0' }}>
                  {/* eslint-disable-next-line */}
                  {state.question.question_title.split(/\n/g).map(i => (
                    <>
                      {i}
                      <br />
                    </>
                  ))}
                </h1>
              </>
            )}
            {state.question.question_types === 'finalize' && state.question.is_test_passed && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left', paddingRight: '0' }}>
                  {(() => {
                    if (state.question.exit_phrase.indexOf('https://t.me/alfa_battle_meta') !== -1) {
                      const arr = state.question.exit_phrase.split('https://t.me/alfa_battle_meta')

                      return (
                        <>
                          {arr[0].split(/\n/g).map((i, j) => (
                            <>
                              {i}
                              {j !== arr[0].split(/\n/g).length - 1 && (
                                <br />
                              )}
                            </>
                          ))} <span><a href='https://t.me/alfa_battle_meta' target='_blank' rel='noopener noreferrer'>@AlfaBattleMeta</a></span>
                          {arr[1].split(/\n/g).map(i => (
                            <>
                              {i}
                              <br />
                            </>
                          ))} 
                        </>
                      )
                    } else {
                      return state.question.exit_phrase.split(/\n/g).map(i => (
                        <>
                          {i}
                          <br />
                        </>
                      ))
                    }
                  })()}
                </h1>
              </>
            )}
            {state.question.question_types === 'finalize' && !state.question.is_test_passed && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left', paddingRight: '0' }}>
                  {/* eslint-disable-next-line */}
                  {state.question.question_title.split(/\n/g).map(i => (
                    <>
                      {i}
                      <br />
                    </>
                  ))}
                </h1>
              </>
            )}
            {/* Questions */}
            {state.question.question_types === 'single' && (
              <RadioGroup items={state.question.question_variety} htmlList={state.question.html_variants} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'coding' && (
              <RadioGroup items={state.question.question_variety} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'coding_multi' && (
              <CheckboxGroup exeptions={state.question.question_single_multi_variety} items={state.question.question_variety.map(i => i.replace(/\|\|\|/g, '\u000A'))} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {(state.question.question_types === 'multi_single' || state.question.question_types === 'task_multi') && (
              <CheckboxGroup countAnswer={state.question?.multiselect_required_count_answer} exeptions={state.question.question_single_multi_variety} items={state.question.question_variety} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'multi_line' && (
              <TableWithLevels
                items={state.question.question_variety}
                headers={state.question.multi_line_headers}
                marks={state.question.multi_line_marks}
                value={state.selectAnswer}
                dispatch={dispatch}
                dispatchType='setSelectAnswer'
              />
            )}
            {state.question.question_types === 'list_or_input' && (
              <AutocompleteInput
                items={state.question.question_variety.map((i, j) => {
                  return { id: j, text: i };
                })}
                value={state.selectAnswer}
                dispatch={dispatch}
                dispatchType='setSelectAnswer'
                onlySelect={false}
              />
            )}
            {state.question.question_types === 'opened_with_load_list' && (
              <AutocompleteInput
                items={state.question.load_data_list.map((i, j) => {
                  return { id: j, text: i };
                })}
                value={state.selectAnswer}
                dispatch={dispatch}
                dispatchType='setSelectAnswer'
                onlySelect={false}
              />
            )}
            {state.question.question_types === 'task' && (
              <RadioGroup items={state.question.question_variety} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'scale' && (
              <Scale items={state.question.question_variety} value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' htmlList={state.question.html_variants} />
            )}
            {state.question.question_types === 'opened' && (
              <TextInput value={state.selectAnswer} placeholder={state.question.placeholder} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'opened_numbers' && (
              <div className='text-input'>
                <input
                  type='number'
                  value={state.selectAnswer}
                  onChange={e => dispatch({ type: 'setSelectAnswer', payload: e.target.value })}
                />
              </div>
            )}
            {state.question.question_types === 'opened_and_empty' && (
              <TextInput value={state.selectAnswer} placeholder={state.question.placeholder} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'opened_text' && (
              <Textarea value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'opened_text_empty' && (
              <Textarea value={state.selectAnswer} dispatch={dispatch} dispatchType='setSelectAnswer' />
            )}
            {state.question.question_types === 'single_other' && (
              <RadioGroupWithOther
                items={state.question.question_variety}
                value={state.selectAnswer}
                dispatch={dispatch}
                dispatchType='setSelectAnswer'
                otherQ={state.question.question_multi_variety}
                otherDispatchType='setOtherAnswer'
                otherAnswer={state.otherAnswer}
              />
            )}
            {state.question.question_types === 'multi_other' && (
              <CheckboxGroupWithOther
                items={state.question.question_variety}
                value={state.selectAnswer}
                dispatch={dispatch}
                dispatchType='setSelectAnswer'
                otherQ={state.question.question_other_input}
                otherDispatchType='setOtherAnswer'
                otherAnswer={state.otherAnswer}
                countAnswer={state.question?.multiselect_required_count_answer}
              />
            )}
            {state.question.question_types === 'file_upload_or_url' && (
              <FileUploader
                isLoading={state.is_pending}
                upload={(file) => dispatch({ type: 'setSelectAnswer', payload: { file } })}
                setUrl={(url) => dispatch({ type: 'setSelectAnswer', payload: { url } })}
              />
            )}
          </div>
        </div>

        {state.question.question_types !== undefined &&
          state.question.question_types !== '' &&
          state.question.question_types !== 'finalize' &&
          state.question.question_types !== 'task_preview_choice' &&
          !state.question.is_last_question && (
            <>
              <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
                <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                  <button onClick={() => handleSubmit()} className='test__link-button' disabled={disableBtn()} style={{ opacity: `${disableBtn() ? 0.7 : 1}` }}>Далее</button>
                </div>
              </div>
              {/* <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
                <button
                  className={`test__btn-next ${disableBtn() ? 'test__btn-next_disabled' : ''}`}
                  type='button'
                  disabled={disableBtn()}
                  onClick={() => handleSubmit()}
                >
                </button>
              </div> */}
            </>
          )}

        {state.question.is_last_question && (
            <>
              <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
                <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                  <button onClick={() => handleSubmit()} className='test__link-button' disabled={disableBtn()} style={{ opacity: `${disableBtn() ? 0.7 : 1}` }}>{state.question.button_text}</button>
                </div>
              </div>
            </>
          )}

        {/* {state.question.question_types === 'task_preview_choice' && !state.question.is_last_question && (
          <div className='test__btn-block_2nd'>
            <>
              <button
                className={`test__btn-next ${state.is_pending ? 'test__btn-next_disabled' : ''}`}
                type='submit'
                disabled={state.is_pending}
                onClick={() =>  handleSubmit([`${state.question.question_variety[1]}`])}
              >
                {state.question.question_variety[1]}
              </button>
              <button
                className={`test__btn-next ${state.is_pending ? 'test__btn-next_disabled' : ''}`}
                type='button'
                disabled={state.is_pending}
                onClick={() =>  handleSubmit([`${state.question.question_variety[0]}`])}
              >
                {state.question.question_variety[0]}
              </button>
            </>
          </div>
        )} */}

        {state.question.question_types !== undefined && state.question.question_types === 'finalize' && state.link !== '' && state.question.is_test_passed && !state.question.is_last_question && (
          <>
            <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
              <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                <button onClick={() => window.open(`${state.link}`)} className='test__link-button'>Далее</button>
              </div>
            </div>
            {/* <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
              <button className='test__btn-next' type='button' onClick={() => window.open(`${state.link}`)}>
              </button>
            </div> */}
            <Footer />
          </>
        )}

        {state.question.question_types !== undefined && state.question.question_types === 'finalize' && !state.question.is_test_passed && !state.question.is_last_question && (
          <>
            <div className='test__btn-block' style={{ justifyContent: 'flex-end' }}>
              <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                <button onClick={() => handleSubmit()} className='test__link-button'>{state.question.button_text}</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestQuestions;
