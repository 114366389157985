import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './Scale.scss';

const Scale = ({ items, value, dispatch, dispatchType, htmlList }) => {
  useEffect(() => {
    if (value.length === 0) {
      dispatch({
        type: dispatchType,
        payload: items.map(() => undefined),
      });
    }
    // eslint-disable-next-line
  }, [dispatch, dispatchType]);

  const handleChecked = (index, val) => {
    const newAnswer = value.map((i, j) => (j === index ? val : i));

    dispatch({
      type: dispatchType,
      payload: newAnswer,
    });
  };

  return (
    <div className='scale'>
      {items.map((answer, index) => (
        <>
          <div className='radio-group-wrapper radio-group-wrapper-level'>
            {htmlList[index] && (
              <div dangerouslySetInnerHTML={{ __html: htmlList[index] }} style={{ fontSize: '14px' }}/>
            )}
            {!htmlList[index] && (
              <div>{answer}</div>
            )}
            <div className='radio-group-list'>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                return (
                  <label key={`radio-group-${index}-${i}`} htmlFor={`radio-group-${index}-${i}`} className='radio-group__label'>
                    <div className='radio-group__label_text'>{i}</div>
                    <input
                      type='radio'
                      id={`radio-group-${index}-${i}`}
                      name='radio question'
                      value={`${answer}: ${i}`}
                      checked={value[index] === `${answer}: ${i}`}
                      onChange={e => handleChecked(index, e.target.value)}
                      className={`radio-group__radio ${value[index] === `${answer}: ${i}` ? 'checked' : ''}`}
                    />
                    <div className={`radio-group__radio-indicator ${i === 10 ? 'last' : ''}`} />
                  </label>
                );
              })}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

Scale.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatchType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Scale;
