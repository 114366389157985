import React from 'react';
import PropTypes from 'prop-types';

import './Textarea.scss';

const Textarea = ({ value, dispatch, dispatchType, placeholder = '' }) => {
  return (
    <div className='textarea'>
      <textarea type='text' value={value} onChange={e => dispatch({ type: dispatchType, payload: e.target.value })} placeholder={placeholder} />
    </div>
  );
};

Textarea.propTypes = {
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
};

export default Textarea;
