import React from 'react';
import { TextInputRegistration } from '../../../components/TextInput/TextInput';
import { firstNameValidation, lastNameValidation, middleNameValidation } from '../../../shared/functions/validations';

const NameField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Фамилия</h1>
      <TextInputRegistration
        value={state.last_name}
        dispatch={dispatch}
        dispatchType='SET_LAST_NAME'
        placeholder='Иванов'
        validation={state.validation}
        error={!lastNameValidation(state.last_name)}
        errorText={'Пожалуйста, введите фамилию'}
        autocomplete='off'
      />

      <h1 className='test__question-title'>Имя</h1>
      <TextInputRegistration
        value={state.first_name}
        dispatch={dispatch}
        dispatchType='SET_FIRST_NAME'
        placeholder='Иван'
        validation={state.validation}
        error={!firstNameValidation(state.first_name)}
        errorText={'Пожалуйста, введите имя'}
        autocomplete='off'
      />

      {/* <h1 className='test__question-title'>Отчество</h1>
      <TextInputRegistration
        value={state.middle_name}
        dispatch={dispatch}
        dispatchType='SET_MIDDLE_NAME'
        placeholder='Иванович'
        validation={state.validation}
        error={!middleNameValidation(state.middle_name)}
        errorText={'Пожалуйста, введите отчество'}
        autocomplete='off'
      /> */}
    </>
  );
};

export default NameField;
