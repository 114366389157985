import React, { Fragment } from 'react'

import './ProgressBar.scss'

const ProgressBar = ({ current_count = 0, count = 0 }) => {

  return (
    <div className='progress-bar'>
      <div className='progress-bar__title'><span className='progress-bar__step'>Шаг: {current_count}</span> <span>из {count}</span></div>
      <div style={{ display: 'flex', position: 'relative' }} className='progress-bar__body'>
        {Array.apply(null, {length: count}).map(Number.call, Number).map((i ,j) => (
          <Fragment key={j}>
            <div
              className={`progress-bar__item`}
              style={{
                width: `calc(100% / ${count})`,
                zIndex: `${count - j}`,
                display: `${j < current_count ? 'flex' : 'none'}`
              }}
            />
            {/* {j === count - 1 && (
              <div
                className={`progress-bar__item_1`}
                style={{
                  left: `calc(${(100 / count) * (j + 1)}% + 1px)`,
                  // backgroundColor: `${j < current_count ? '#EF3124' : 'transparent'}`,
                  zIndex: `${count - j}`
                }}
              />
            )} */}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ProgressBar
