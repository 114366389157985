import React from 'react';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../components/TextInput';
import { testEmail, testEmailAlfa } from '../../../shared/functions/validations';

const EmailField = ({ state, dispatch }) => {
  const history = useHistory()

  return (
    <>
      <h1 className='test__question-title'>{history.location.pathname === '/alfa-employees' ? 'Ваш корпоративный e-mail в Альфа-Банке' : 'Ваш e-mail'}</h1>
        <TextInput
          value={state.email}
          dispatch={dispatch}
          dispatchType='SET_EMAIL'
          placeholder={history.location.pathname === '/alfa-employees' ? 'example@alfabank.ru' : 'example@email.com'}
          validation={state.validation}
          error={history.location.pathname === '/alfa-employees' ? !testEmailAlfa(state.email) : !testEmail(state.email)}
          errorText={`Пожалуйста, введите email в формате ${history.location.pathname === '/alfa-employees' ? 'example@alfabank.ru' : 'example@email.com'}`}
        />
    </>
  );
};

export default EmailField;
