import React from 'react';
import { TextInputRegistration } from '../../../components/TextInput/TextInput';

const NameField = ({ state, dispatch }) => {
  return (
    <>
      <div className='test-tilda__field'>
        <h1 className='test__question-title'>Фамилия*</h1>
        <TextInputRegistration
          value={state.last_name}
          dispatch={dispatch}
          dispatchType='SET_LAST_NAME'
          placeholder='Иванов'
          validation={state.validation}
          error={state.last_name.trim().length === 0 || state.last_name.trim().replace(/,/gi, '').replace(/\*/gi, '').replace(/;/gi, '').length === 0}
          errorText={'Пожалуйста, введите фамилию'}
          autocomplete='off'
        />
      </div>

      <div className='test-tilda__field'>
        <h1 className='test__question-title'>Имя*</h1>
        <TextInputRegistration
          value={state.first_name}
          dispatch={dispatch}
          dispatchType='SET_FIRST_NAME'
          placeholder='Иван'
          validation={state.validation}
          error={state.first_name.trim().length === 0 || state.first_name.trim().replace(/,/gi, '').replace(/\*/gi, '').replace(/;/gi, '').length === 0}
          errorText={'Пожалуйста, введите имя'}
          autocomplete='off'
        />
      </div>
    </>
  );
};

export default NameField;
