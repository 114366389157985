export const initialState = {
  living_city_list: [],
  current_count: 1,

  first_name: '',
  middle_name: '',
  last_name: '',
  phone: '',
  email: '',
  dob: '',
  is_russian_citizenship: '',
  region: '',
  living_city: '',
  pd_agreement: false,

  is_pending: false,

  link_test: '',

  close_test_text: '',

  validation: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'VALIDATION_ON':
      return {
        ...state,
        validation: true,
      };

    case 'VALIDATION_OFF':
      return {
        ...state,
        validation: false,
      };

    case 'GET_LIVING_CITY_LIST':
      return {
        ...state,
        living_city_list: action.payload,
      };

    case 'DECREMENT_COUNT':
      return {
        ...state,
        current_count: state.current_count - 1,
      };

    case 'INCREMENT_COUNT':
      return {
        ...state,
        current_count: state.current_count + 1,
      };

    case 'SET_FIRST_NAME':
      return {
        ...state,
        first_name: action.payload,
      };

    case 'SET_MIDDLE_NAME':
      return {
        ...state,
        middle_name: action.payload,
      };

    case 'SET_LAST_NAME':
      return {
        ...state,
        last_name: action.payload,
      };

    case 'SET_PHONE':
      return {
        ...state,
        phone: action.payload,
      };

    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };

    case 'SET_DOB':
      return {
        ...state,
        dob: action.payload,
      };

    case 'SET_RUSSIAN_CITIZENSHIP':
      return {
        ...state,
        is_russian_citizenship: action.payload,
      };

    case 'SET_LIVING_CITY':
      return {
        ...state,
        living_city: action.payload,
      };

    case 'SET_PD_AGREEMENT':
      return {
        ...state,
        pd_agreement: action.payload,
      };

    case 'SET_PENDING':
      return {
        ...state,
        is_pending: action.payload,
      };

    case 'SET_CLOSE_TEXT':
      return {
        ...state,
        close_test_text: action.payload,
      };

    case 'REGISTRATION_CONFIRM':
      return {
        ...state,
        link_test: action.payload.link,
        close_test_text: action.payload.text,
      };

    default:
      throw new Error();
  }
}
