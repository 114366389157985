import React from 'react';
import PropTypes from 'prop-types';

import './TextInput.scss';

const TextInput = ({ value, dispatch, dispatchType, placeholder = '', validation, errorText, error, type }) => {
  return (
    <div className='text-input'>
      <input
        type='text'
        value={value}
        onChange={e => {
          if (type === 'phone') {
            if (/^\d+$/.test(e.target.value) && e.target.value.length <= 20) {
              dispatch({ type: dispatchType, payload: e.target.value })
            }

            if (e.target.value === '') {
              dispatch({ type: dispatchType, payload: '' })
            }
          } else {
            dispatch({ type: dispatchType, payload: e.target.value })
          }
        }}
        placeholder={placeholder}
      />
      {validation && error && (
        <span>{errorText}</span>
      )}
    </div>
  );
};

export const TextInputRegistration = ({ value, dispatch, dispatchType, placeholder = '', validation, errorText, error }) => {
  return (
    <div className='text-input'>
      <input
        type='text'
        value={value}
        onChange={e => {
          return dispatch({ type: dispatchType, payload: e.target.value })}
        }
        placeholder={placeholder}
      />
      {validation && error && (
        <span>{errorText}</span>
      )}
    </div>
  );
};

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
};

export default TextInput;
