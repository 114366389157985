/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactAutocomplete from 'react-autocomplete';

import './AutocompleteInput.scss';

const AutocompleteInput = ({ items, value, dispatch, disable = false, dispatchType, onlySelect = true }) => {
  const [currentValue, setCurrentValue] = useState(value);

  return (
    <div className='autocomplete-input' style={disable ? { pointerEvents: 'none', opacity: 0.3 } : {}}>
      <ReactAutocomplete
        items={items}
        shouldItemRender={(i, j) => i.text.toLowerCase().indexOf(j.toLowerCase()) > -1}
        getItemValue={i => i.text}
        renderItem={(i, highlighted) => (
          <div key={i.id} style={{ backgroundColor: highlighted ? '#EF3124' : 'transparent', color: highlighted ? '#fff' : '#000', padding: '5px 0' }}>
            {i.text}
          </div>
        )}
        renderMenu={(items, _value, style) => {
          return (
            <div
              style={{
                ...style,
                position: 'absolute',
                top: '55px',
                backgroundColor: '#e2e2e2',
                left: '0',
                zIndex: '99999',
                maxHeight: '200px',
                overflowY: 'auto'
              }}
            >
                {items}
            </div>
          );
        }}
        value={currentValue}
        onChange={e => {
          setCurrentValue(e.target.value);

          if (!onlySelect) {
            dispatch({
              type: dispatchType,
              payload: e.target.value,
            });
          }
        }}
        onSelect={i => {
          setCurrentValue(i);
          dispatch({
            type: dispatchType,
            payload: i,
          });
        }}
        menuStyle={{
          width: '100%',
          marginTop: '10px',
          cursor: 'pointer',
        }}
      />
    </div>
  );
};

AutocompleteInput.defaultProps = {
  onlySelect: true,
  disable: false,
};

AutocompleteInput.propTypes = {
  value: PropTypes.string.isRequired,
  onlySelect: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

export default AutocompleteInput;
