/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import './FileUploader.scss';

const FileUploader = ({ upload, setUrl, isLoading,  }) => {
  const [file, setFile] = useState();
  const [url, setCustomUrl] = useState('')

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!isLoading && acceptedFiles[0].type === 'application/pdf' && acceptedFiles[0].size < 2097153) {
        setFile(acceptedFiles[0]);
        upload(acceptedFiles[0])
      }

      if (acceptedFiles[0].type !== 'application/pdf') {
        alert('Пожалуйста, загрузите файл в формате PDF')
        return;
      }

      if (acceptedFiles[0].size > 2097153) {
        alert('Файл должен весить не больше 2мб');
        return;
      }
    },
    [isLoading]
  );

  const clearFile = () => {
    setFile();
    upload();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: false,
    imediateRemove: true,
    disabled: url !== ''
  });

  return (
    <div className='file-uploader-wrapper'>
      <div className='attachments-block__upload-field' style={{ opacity: `${url === '' ? 1 : 0.5}` }}>
        <div {...getRootProps({ className: 'dropzone' })} onClick={() => (!isLoading || url === '') && open ? open() : null}>
          {file && (
            <>
              <p>{file.name}</p>
              <button style={{ backgroundColor: '#EF3124', color: 'white', border: 0, padding: '5px 30px', marginTop: '10px' }} onClick={clearFile}>Удалить</button>
            </>
          )}
          {!file && (
            <>
              <input {...getInputProps()} />
              <p>Выберите файл в формате PDF</p>
            </>
          )}
        </div>
      </div>
        <div className='text-input' style={{ marginTop: '50px' }}>
          <input
            type='text'
            value={url}
            disabled={file}
            style={{ opacity: `${!file ? 1 : 0.5}` }}
            onChange={e => {
              setCustomUrl(e.target.value)
              setUrl(e.target.value)
            }}
            placeholder={'Или вставьте ссылку'}
          />
      </div>
    </div>
  );
};

export default FileUploader;
