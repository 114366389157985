export const initialState = {
  question: {},
  answerId: '',
  selectAnswer: '', // Ответ
  taskQuestionPending: false,
  isEnd: false,
  isError: false,
  isPending: false,
  link: '',
  question_number: '',
  questions_total: '',
  otherAnswer: '',
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'setOtherAnswer':
      return {
        ...state,
        otherAnswer: action.payload,
      };

    case 'getQuestionCount':
      return {
        ...state,
        question_number: action.payload.question_number,
        questions_total: action.payload.questions_total,
      };

    case 'getQuestion':
      return {
        ...state,
        question: action.payload,
      };

    case 'getLink':
      return {
        ...state,
        link: action.payload,
      };

    case 'setTaskQuestionPending':
      return {
        ...state,
        taskQuestionPending: action.payload,
      };

    case 'setAnswerId':
      return {
        ...state,
        answerId: action.payload,
      };

    case 'setSelectAnswer':
      return {
        ...state,
        selectAnswer: action.payload,
      };

    case 'setPending':
      return {
        ...state,
        isPending: action.payload,
      };

    case 'setError':
      return {
        ...state,
        isError: action.payload,
      };

    default:
      throw new Error();
  }
}
